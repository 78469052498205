// import { createRouter, createWebHistory } from 'vue-router'    
import { createRouter, createWebHashHistory } from 'vue-router'  // desabilita o modo historico (melhor)
import HomeView from '../views/HomeView.vue'
import QddView from '../views/QddView.vue'
import OrcNaturezaView  from '../views/OrcNaturezaView.vue'
import OrcSubelementoView  from '../views/OrcSubelementoView.vue'
import GraRecCatEcoView  from '../views/GraRecCatEcoView.vue'
import PpaView  from '../views/PpaView.vue'
import EsicView  from '../views/E-Sic.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/qdd',
    name: 'qdd',
    component: QddView
  },
  {
    path: '/grareccateco',
    name: 'grareccateco',
    component: GraRecCatEcoView
  },
  {
    path: '/ppa',
    name: 'ppa',
    component: PpaView
  },  
  {
    path: '/orcnatureza',
    name: 'orcnatureza' ,
    component: OrcNaturezaView
  },
  {
    path: '/esic',
    name: 'esic' ,
    component: EsicView
  },
  {
    path: '/orcsubelemento',
    name: 'orcsubelemento' ,
    component: OrcSubelementoView
  }  
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
